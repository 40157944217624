import React, { useState, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {format} from 'date-fns';
import {cs} from 'date-fns/locale';
import {useLocation} from 'react-router-dom';
import axios from 'axios';
import baseAppConfig from './base-app-config';
import ReCAPTCHA from "react-google-recaptcha";
import Legend from "./component/Legend";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function App() {
    const [appConfig, setAppConfig] = useState(null);
    const [isLoadingConfig, setIsLoadingConfig] = useState(true);

    const fetchAppConfig = () => {
        setIsLoadingConfig(true);
        axios.get(baseAppConfig.CONFIG_URL)
            .then(response => {
                const data = response.data.app_config;
                const today = new Date();
                if (new Date(data.min_bookable_date) < today) {
                    setAppConfig({
                      ...data,
                      min_bookable_date: today
                    });
                } else {
                    setAppConfig(data);
                }
            })
            .catch(error => {
                throw Error('Cannot load application configuration!');
            }).finally(() => {
                setIsLoadingConfig(false);
        });
    };

    const query = useQuery();
    const progType = query.get('progType') || ''; // Nastavení výchozí hodnoty

    const [formData, setFormData] = useState({
        selectedDate: '',
        schoolName: '',
        schoolAddress: '',
        contactPersonName: '',
        phoneNumber: '',
        email: '',
        childCount: '',
        className: '',
        programme: progType,
        additionalInfo: '',
        agreeWithTos: false
    });
    const [wasValidated, setWasValidated] = useState(false);
    const [calendarData, setCalendarData] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const recaptchaRef = useRef(null); // Create a ref for reCAPTCHA

    useEffect(() => {
        fetchAppConfig();
    }, []);

    useEffect(() => {
        if (!isLoadingConfig) {
            fetchCalendarData();
        }
    }, [isLoadingConfig, appConfig]);

    const fetchCalendarData = () => {
        setIsSubmitting(true);
        // Fetch calendar data based on currently selected month
        axios.get(appConfig.api_eps.booking_entry_list_reserved)
            .then(response => {
                setCalendarData(response.data);
                // console.log("Calendar data fetched: ", response.data);
            })
            .catch(error => {
                console.error('Error fetching calendar data:', error);
            }).finally(() => {
                setIsSubmitting(false);
            });
    };

    const handleSubmit = async event => {
        event.preventDefault();
        const errors = {};
        setWasValidated(true);

        // Check mandatory fields
        if (!formData.selectedDate) errors.selectedDate = 'Vyberte datum';
        if (!formData.programme) errors.programme = 'Zvolte program';
        if (!formData.schoolName) errors.schoolName = 'Vyplňte název školy';
        // if (!formData.schoolAddress) errors.schoolAddress = 'Vyplňte adresu školy';
        if (!formData.contactPersonName) errors.contactPersonName = 'Vyplňte kontaktní osobu';
        if (!formData.phoneNumber) errors.phoneNumber = 'Vyplňte telefon';
        if (!formData.email || !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(formData.email)) errors.email = 'Vyplňte platnou e-mailovou adresu';
        if (!formData.childCount) errors.childCount = 'Vyplňte počet dětí';
        if (!formData.className) errors.className = 'Vyplňte název třídy';
        if (!formData.agreeWithTos) errors.agreeWithTos = 'Zaškrtněte souhlas s podmínkami';

        if (!recaptchaToken) {
            errors.gRecaptcha = 'Potvrďte, že nejste robot';
        }

        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }

        setIsSubmitting(true); // Set loading state

        try {
            // Verify reCAPTCHA token on server-side
            const recaptchaResponse = await axios.post(appConfig.recaptcha_verify_url, { token: recaptchaToken });

            if (!recaptchaResponse.data.success) {
                setValidationErrors({ gRecaptcha: 'Ověření reCAPTCHA selhalo. Zkuste to prosím znovu.' });
                setIsSubmitting(false);
                return;
            }

            // Fetch selected date availability from API
            const dateAvailabilityResponse = await axios.get(appConfig.api_eps.booking_entry_check_availability + format(formData.selectedDate, 'yyyy-MM-dd', {locale: cs}));
            const dateAvailabilityData = dateAvailabilityResponse.data;

            // Validate availability
            if (!dateAvailabilityData.available) {
                setValidationErrors({ selectedDate: 'Zvolený termín již bohužel není k dispozici, zvolte jiný' });
                setFormData({
                    ...formData,
                    selectedDate: ''
                });
                fetchCalendarData();
                // Reset reCAPTCHA
                recaptchaRef.current.reset();
                setRecaptchaToken(null);

                return;
            }

            // Create booking entry
            const response = await fetch(appConfig.api_eps.booking_entry_create, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...formData,
                    selectedDate: format(formData.selectedDate, 'yyyy-MM-dd', { locale: cs }) // Format date as YYYY-MM-DD
                })
            });

            if (!response.ok) {
                console.error('Error submitting form:', response.statusText);
                window.location.href = appConfig.redirect_url_error;
                return;
            }

            const data = await response.json();

            // Clear form fields
            setFormData({
                selectedDate: '',
                schoolName: '',
                schoolAddress: '',
                contactPersonName: '',
                phoneNumber: '',
                email: '',
                childCount: '',
                className: '',
                programme: '',
                additionalInfo: '',
                agreeWithTos: false
            });
            setWasValidated(false);
            window.location.href = appConfig.redirect_url_success + data.authHash;

            // Reset reCAPTCHA
            recaptchaRef.current.reset();
            setRecaptchaToken(null);

            // Fetch updated calendar data after submitting form
            fetchCalendarData();
        } catch (error) {
            console.error('Error:', error);
            setValidationErrors({ api: 'Error fetching data from API' });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDateChange = date => {
        // Update selected date in form data when calendar date changes
        setFormData({...formData, selectedDate: date});
        setValidationErrors({...validationErrors, selectedDate: undefined})
        // console.log('Date changed: ', date);
    };

    function isWeekend(date) {
        // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        const dayOfWeek = date.getDay();
        // Check if the day of the week is either Saturday (6) or Sunday (0)
        return dayOfWeek === 0 || dayOfWeek === 6;
    }

    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because month is zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function formatDateToCz(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1); // Adding 1 because month is zero-indexed
        const day = String(date.getDate());
        return `${day}. ${month}. ${year}`;
    }

    const tileDisabled = ({date, view}) => {
        // Disable tiles in month view only
        if (view === 'month') {
            if (!appConfig.booking_weekend_days_allowed && isWeekend(date)) {
                return date;
            }
            const key = formatDate(date);
            if (calendarData.hasOwnProperty(key)) {
                const dateInfo = calendarData[key];
                return dateInfo.locked ? date : undefined;
            }
            return undefined;
        }
    };

    const tileClassName = ({date, view}) => {
        // Set className for tiles in month view only
        if (view === 'month') {
            if (isWeekend(date)) {
                return '';
            }
            const key = formatDate(date);
            if (calendarData.hasOwnProperty(key)) {
                const dateInfo = calendarData[key];
                return dateInfo.className ? 'day-'.concat(dateInfo.className) : '';
            }
            return '';
        }
    };

    if (isLoadingConfig) {
        return <div>
            <div className="overlay">
                <div className="throbber"><img src="./images/IKONA_12_opt.png" alt="Ikona nahrávání"/></div>
            </div>
        </div>;
    }

    return (
        <div>
            {isSubmitting && (
                <div className="overlay">
                    <div className="throbber"><img src="./images/IKONA_12_opt.png" alt="Ikona nahrávání"/></div>
                </div>
            )}
            <header className="row mb-3">
                <div className="col-12 text-center">
                    <h1 className="my-2">
                        <img className="me-4" src="./images/VEVERKA.png" width="100px" alt="Ilustrace zrzavé veverky"/> LESNÍ
                        ŠKOLA - REZERVAČNÍ FORMULÁŘ
                    </h1>
                </div>
            </header>
            <div className="container">
                <main>
                    <div className="row">
                        <div className='col-lg-6 mb-5 px-lg-5 text-center'>
                            <div className="row mb-3">
                                <div className='col-6 text-end'>
                                    <img src="./images/KALENDAR.png" width="200px"
                                         alt="Ilustrace kalendáře s tužkou a zaškrtnutými termíny"/>
                                </div>
                                <div className='col-6 text-start'>
                                    <h2 className="calendar-title">VOLNÉ TERMÍNY</h2>
                                    <p>
                                        Termín: <strong>{formData.selectedDate ? formatDateToCz(formData.selectedDate) : ''}</strong><br/>
                                        {validationErrors.selectedDate &&
                                            <span
                                                className="text-danger"><strong>{validationErrors.selectedDate}</strong></span>}
                                    </p>
                                </div>
                            </div>
                            <Calendar
                                view="month"
                                defaultActiveStartDate={new Date(appConfig.min_bookable_date)}
                                minDetail="month"
                                maxDetail="month"
                                minDate={new Date(appConfig.min_bookable_date)}
                                maxDate={new Date(appConfig.max_bookable_date)}
                                onChange={handleDateChange}
                                value={formData.selectedDate}
                                tileClassName={tileClassName}
                                tileDisabled={tileDisabled}
                            />
                            <Legend />
                        </div>
                        <div className="col-lg-6">
                            <form noValidate onSubmit={handleSubmit} className={wasValidated ? 'was-validated' : ''}>
                                <div className="row mb-2">
                                    <label htmlFor="programme" className="col-sm-3 col-form-label">Program</label>
                                    <div className="col-sm-9">
                                        <select
                                            id="programme"
                                            value={formData.programme}
                                            className="form-select"
                                            aria-describedby="programmeHelpInline"
                                            onChange={e => setFormData({...formData, programme: e.target.value})}
                                            required
                                        >
                                            <option value="">(zvol program)</option>
                                            <option value="program1">LES VŠEMI SMYSLY</option>
                                            <option value="program2">VÝPRAVA ZA STROMY</option>
                                            <option value="program3">PŘÍBĚH STROMU</option>
                                        </select>
                                        {validationErrors.programme &&
                                            <span id="programmeHelpInline"
                                                  className="invalid-feedback"><strong>{validationErrors.programme}</strong></span>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label htmlFor="schoolName" className="col-sm-3 col-form-label">Název školy</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            id="schoolName"
                                            value={formData.schoolName}
                                            className="form-control"
                                            aria-describedby="schoolNameHelpInline"
                                            onChange={e => setFormData({...formData, schoolName: e.target.value})}
                                            required
                                        />
                                        {validationErrors.schoolName &&
                                            <span id="schoolNameHelpInline"
                                                  className="invalid-feedback"><strong>{validationErrors.schoolName}</strong></span>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label htmlFor="schoolAddress" className="col-sm-3 col-form-label">Adresa
                                        školy</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            id="schoolAddress"
                                            value={formData.schoolAddress}
                                            className="form-control"
                                            aria-describedby="schoolAddressHelpInline"
                                            onChange={e => setFormData({...formData, schoolAddress: e.target.value})}
                                        />
                                        {validationErrors.schoolAddress &&
                                            <span id="schoolAddressHelpInline"
                                                  className="invalid-feedback"><strong>{validationErrors.schoolAddress}</strong></span>}
                                        <span><small>(nepovinné)</small></span>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label htmlFor="contactPersonName" className="col-sm-3 col-form-label">Kontaktní
                                        osoba</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            id="contactPersonName"
                                            value={formData.contactPersonName}
                                            className="form-control"
                                            aria-describedby="contactPersonNameHelpInline"
                                            onChange={e => setFormData({
                                                ...formData,
                                                contactPersonName: e.target.value
                                            })}
                                            required
                                        />
                                        {validationErrors.contactPersonName &&
                                            <span id="contactPersonNameHelpInline"
                                                  className="invalid-feedback"><strong>{validationErrors.contactPersonName}</strong></span>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label htmlFor="phoneNumber" className="col-sm-3 col-form-label">Telefon</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="tel"
                                            id="phoneNumber"
                                            value={formData.phoneNumber}
                                            className="form-control"
                                            aria-describedby="phoneNumberHelpInline"
                                            onChange={e => setFormData({...formData, phoneNumber: e.target.value})}
                                            required
                                        />
                                        {validationErrors.phoneNumber &&
                                            <span id="phoneNumberHelpInline"
                                                  className="invalid-feedback"><strong>{validationErrors.phoneNumber}</strong></span>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label htmlFor="email" className="col-sm-3 col-form-label">E-mail</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="email"
                                            id="email"
                                            pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                                            value={formData.email}
                                            className="form-control"
                                            aria-describedby="phoneHelpInline"
                                            onChange={e => setFormData({...formData, email: e.target.value})}
                                            required
                                        />
                                        {validationErrors.email &&
                                            <span id="emailHelpInline"
                                                  className="invalid-feedback"><strong>{validationErrors.email}</strong></span>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label htmlFor="childCount" className="col-sm-3 col-form-label">Počet dětí</label>
                                    <div className="col-sm-3">
                                        <input
                                            type="number"
                                            id="childCount"
                                            value={formData.childCount}
                                            className="form-control"
                                            aria-describedby="childCountHelpInline"
                                            onChange={e => setFormData({...formData, childCount: e.target.value})}
                                            required
                                        />
                                        {validationErrors.childCount &&
                                            <span id="childCountHelpInline"
                                                  className="invalid-feedback"><strong>{validationErrors.childCount}</strong></span>}
                                    </div>
                                    <label htmlFor="className"
                                           className="col-sm-3 text-start text-sm-end col-form-label">Třída</label>
                                    <div className="col-sm-3">
                                        <input
                                            type="text"
                                            id="className"
                                            value={formData.className}
                                            className="form-control"
                                            aria-describedby="classNameHelpInline"
                                            onChange={e => setFormData({...formData, className: e.target.value})}
                                            required
                                        />
                                        {validationErrors.className &&
                                            <span id="classNameHelpInline"
                                                  className="invalid-feedback"><strong>{validationErrors.className}</strong></span>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label htmlFor="additionalInfo" className="col-sm-3 col-form-label">Doplňující
                                        informace</label>
                                    <div className="col-sm-9">
                                        <textarea
                                            id="additionalInfo"
                                            value={formData.additionalInfo}
                                            className="form-control"
                                            aria-describedby="additionalInfoHelpInline"
                                            onChange={e => {
                                                var newValue =
                                                    e.target.value.length > appConfig.max_additional_info_input_length ?
                                                        e.target.value.substring(0, appConfig.max_additional_info_input_length) :
                                                        e.target.value;
                                                setFormData({...formData, additionalInfo: newValue});
                                            }}
                                        />
                                        <span id="additionalInfoHelpInline" className=""><small>(nepovinné, max. {appConfig.max_additional_info_input_length} znaků)</small></span>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-sm-3"></div>
                                    <div className="col-sm-9">
                                        <div className="form-check mb-3">
                                            <input
                                                type="checkbox"
                                                id="agreeWithTos"
                                                checked={formData.agreeWithTos}
                                                className={`form-check-input`}
                                                aria-describedby="agreeWithTosHelpInline"
                                                onChange={e => {
                                                    setFormData({...formData, agreeWithTos: e.target.checked});
                                                    setValidationErrors({...validationErrors, agreeWithTos: undefined});
                                                }}
                                                required
                                            />
                                            <label className="form-check-label" htmlFor="agreeWithTos">
                                                Souhlasím s <a
                                                href={appConfig.redirect_url_gdpr}
                                                title="Podmínkami zpracování osobních údajů"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                podmínkami zpracování osobních údajů
                                            </a>
                                            </label>
                                            {validationErrors.agreeWithTos &&
                                                <span id="agreeWithTosHelpInline"
                                                      className="invalid-feedback"><strong>{validationErrors.agreeWithTos}</strong></span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-sm-3"></div>
                                    <div className="col-sm-9">
                                        <ReCAPTCHA
                                            ref={recaptchaRef} // Assign ref to ReCAPTCHA
                                            sitekey={appConfig.recaptcha_site_key}
                                            onChange={token => {
                                                setRecaptchaToken(token);
                                                setValidationErrors({...validationErrors, gRecaptcha: undefined});
                                            }}
                                        />
                                        {validationErrors.gRecaptcha &&
                                            <span id="gRecaptchaHelpInLine"
                                                  className="grecaptcha-invalid"><strong>{validationErrors.gRecaptcha}</strong></span>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-6 text-start">
                                        <a role="button" href={appConfig.redirect_url_mother_site_url}
                                           className="btn btn-primary btn-my-gray">
                                            ZPĚT NA ÚVOD
                                        </a>
                                    </div>
                                    <div className="col-6 text-end">
                                        <button className="btn btn-primary btn-my-orange" type="submit">
                                            ODESLAT REZERVACI
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
            <div className="container-fluid ftr-bg">
                <footer className="footer py-5">
                    <p className="pt-5">©{(new Date()).getFullYear() > 2024 ? '2024-' : ''}{(new Date()).getFullYear()} Rezervace | Lesní škola Ostrava</p>
                </footer>
            </div>
        </div>
    );
}

export default App;
