import React from 'react';
import './Legend.css';

function Legend() {
  return (
    <div className="legend-container">
      <div className="square white-square"></div>
      <div className="text">Volný termín</div>

      <div className="square blue-square"></div>
      <div className="text">Zvolený termín</div>

      <div className="square gray-square"></div>
      <div className="text text-last">Obsazený termín</div>
    </div>
  );
}

export default Legend;
